import {cyan, indigo, slate, teal} from "tailwindcss/colors"
import {useLayoutStore} from "@/store/layout"

export const $getCurrentModule = () => {
    const module = useLayoutStore().currentModule
    return module ? appModules[module] : null
}

export const $getModuleIcon = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.icon ?? null
    return appModules[module]?.icon ?? null
}

export const $getModuleColor = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.color ?? null
    return appModules[module]?.color ?? null
}

export const $getModuleTextColor = (module?: string, level?: string | number): string | null => {
    if (!module) return $getCurrentModule()?.color?.text[level || 'DEFAULT'] ?? null
    return appModules[module]?.color?.text[level || 'DEFAULT'] ?? null
}

export const $getModuleBackgroundColor = (module?: string, level?: string | number): string | null => {
    if (!module) return $getCurrentModule()?.color?.background[level || 'DEFAULT'] ?? null
    return appModules[module]?.color?.background[level || 'DEFAULT'] ?? null
}

const primary = {
    '50': '#fffaeb',
    '100': '#fffaeb',
    '200': '#fff2c6',
    '300': '#ffe388',
    '400': '#ffce4a',
    '500': '#ffbe31',
    '600': '#f99607',
    '700': '#dd6f02',
    '800': '#b74d06',
    '900': '#943a0c',
    '950': '#7a300d',
    DEFAULT: '#ffbe31'
}

const secondary = {
    '50': '#f8fafc',
    '100': '#f1f5f9',
    '200': '#e2e8f0',
    '300': '#cbd5e1',
    '400': '#94a3b8',
    '500': '#64748b',
    '600': '#475569',
    '700': '#334155',
    '800': '#1e293b',
    '900': '#0f172a',
    '950': '#020617',
    DEFAULT: '#64748b'
}

const devices = {
    ...cyan,
    '400': cyan['500'],
    '500': cyan['600'],
    '600': cyan['700'],
    DEFAULT: cyan['600']
}

export const appModules: AppModules = {
    dashboard: {
        color: secondary,
        name: 'dashboard',
        icon: 'heroicons:home'
    },
    orders: {
        color: primary,
        name: 'orders',
        icon: 'heroicons:shopping-bag',
        search: {
            detail: 'orders-id-data'
        },
    },
    organisations: {
        color: teal,
        name: 'organisations',
        icon: 'heroicons:truck',
        search: {
            detail: 'organisations-id-organisationBaseData'
        },
    },
    settings: {
        name: 'settings',
        color: secondary,
        icon: 'heroicons:cog-6-tooth',
    },
    baseData: {
        color: secondary,
        name: 'settings',
        icon: 'heroicons:wrench-screwdriver',
    },
    devices: {
        color: devices,
        name: 'devices',
        icon: 'heroicons:bookmark',
        search: {
            listing: 'baseData-devices',
            detail: 'baseData-devices-id',
        },
    },
    categories: {
        color: secondary,
        name: 'categories',
        icon: 'hugeicons:tag-01',
        search: {
            listing: 'baseData-categories',
            detail: 'baseData-categories-id',
        },
    },
    costCenters: {
        color: secondary,
        name: 'costCenters',
        icon: 'ph:coins-light',
        search: {
            listing: 'baseData-costCenters',
            detail: 'baseData-costCenters-id',
        },
    },
    users: {
        name: 'users',
        icon: 'heroicons:users',
        color: secondary,
        search: {
            detail: 'users-id-userBaseData',
            nameProp: 'fullname'
        },
    },
}
